import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import './contact.css'; // Import the CSS file

const ContactForm = () => {
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});

    const navigate = useNavigate();
    
    const validate = () => {
        const errors = {};

        if (!name) {
            errors.name = 'Name is required';
        }

        const phonePattern = /^[0-9]{10}$/;
        if (!phoneNumber) {
            errors.phoneNumber = 'Phone number is required';
        } else if (!phonePattern.test(phoneNumber)) {
            errors.phoneNumber = 'Phone number is invalid';
        }

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email) {
            errors.email = 'Email is required';
        } else if (!emailPattern.test(email)) {
            errors.email = 'Email is invalid';
        }

        return errors;
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

         const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        
        // Construct the data object to be sent to the webhook
        const formData = {
            name,
            phoneNumber,
            email
        };

        // Replace 'YOUR_WEBHOOK_URL' with your actual webhook URL
        const response = await fetch('https://webhooks.integrately.com/a/webhooks/e331462877e64af6951dca0b05c9b85a', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        });

        // Handle the response from the webhook
        if (response.ok) {
            console.log('Form submitted successfully!');

            // window.location("localhost:3000/thankyou.html")
            navigate("/thankyou")
        } else {
            console.error('Error submitting form:', response.statusText);
        }
    };

    return (
        <div id="contact-us">

            <div className='background-container'>
            
            <div className="row">
                <div className="col-md-6">
                 <h1>CONTACT US</h1>
                <h2>REACH OUT TO US</h2>
                <p className='contact-p'>
                    Feel free to get in touch with us at
                    Sylvanscape.<br />
                   Whether you have questions about our PMRDA
sanctioned <br /> residential projects  near Hinjewadi,
want to schedule a site visit to <br /> our residential
NA plots near Pune, or need more <br /> information
on our  RERA registered plots and villas near
Pune, <br />our dedicated team is here to assist
you.123
                    
                </p>
                <h3>Call Us On</h3>
                <p  className="contact-number"> India : <a href="tel:7219191500">+91 7219191500</a> / <a href="tel:02048481233">+91 02048481233</a></p>
                <p className="contact-number"> USA : <a href="tel:+12012202329">+1 201 220-2329</a></p>
                </div>
                
                <div className="col-md-6">
               
                <form onSubmit={handleSubmit} className="form-container">
                    <div className="form">
                        <label>Name:</label>
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>Phone Number:</label>
                        <input
                            type="tel"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            required
                        />
                        {errors.phoneNumber && <span className="error">{errors.phoneNumber}</span>}
                    </div>
                    <div>
                        <label>Email:</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                         {errors.email && <span className="error">{errors.email}</span>}
                    </div>
                    <button type="submit">Submit</button>
                </form>
               
                </div>
            </div>
            
               
              
                
            </div>
        </div>
    );
};

export default ContactForm;
