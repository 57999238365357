import React from 'react';
import './footer.css'; // Import the CSS file
import 'bootstrap-icons/font/bootstrap-icons.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-address">
                    <h3> Our Address </h3>
                    <p>617, Gaikwad Villa, Oppo. Seasons Business  </p><p>Square / Punjab National Bank, Hotel Sarja lane,</p><p> Aundh, Pune - 411008</p>
                </div>
                <div className="footer-text">
                    <p>Copyright &#169; 2024 Sylvanscape. All Rights Reserved.<br />Sylvanscape and all it's assets including the domain <br /> sylvanscape.co are wholly owned by Ecstasy Communities Private Ltd.</p>
                    <div className="social">
                    <a href="https://www.facebook.com/Sylvanscape/" target = "_blank"><i className="bi bi-facebook"></i></a>
                    <a href="https://www.instagram.com/sylvanscape4/" target = "_blank"><i className="bi bi-instagram"></i></a>
                    <a href="https://www.linkedin.com/company/sylvanscape" target = "_blank"><i className="bi bi-linkedin"></i></a>
                    <a href="https://www.youtube.com/@Sylvanscapepune" target = "_blank"><i className="bi bi-youtube"></i></a>
                    <a href="https://twitter.com/sylvanscapepune" target = "_blank"><i className="bi bi-twitter"></i></a>                    
                    </div>
                </div>
                <div className="footer-contact">
                    <h3>Contact Us</h3>
                    <p>Email: <a href="mailto:info@sylvanscape.co">info@sylvanscape.co</a></p>
    
                    <p>Call Us:<br/> India: <a href="tel:7219191500">+91 7219191500</a> / <a href="tel:02049481233">+91 02048481233</a></p>
                    <p>USA: <a href="tel:+12012202329">+1 201 220-2329</a></p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
